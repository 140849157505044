<template>
  <section class="d-flex flex-column justify-center align-center mt-5">
    <h1 class="text-center text-gold">{{ $t('In which manner do you like to collaborate with us?') }}</h1>
    <article class="d-flex flex-wrap justify-center mt-10">
      <v-card class="d-flex mx-3 purple-gradient shadow" height="350" width="275" 
        v-for="(method, i) in contributeMethods" :key="i" 
        @mouseenter="swapAndAnimateCardMethodText($event, method)" @mouseleave="swapAndAnimateCardMethodText($event, method)"
      >
        <v-card-text class="my-auto" :class="method.class">{{ $t(method.hovering ? method.how : method.title) }}</v-card-text>
      </v-card>
    </article>
    <article class="d-flex flex-column align-center mt-10 mb-5">
      <h2 class="text-gold text-center mb-2">{{ $t('Many Thanks!') }}</h2>
      <span class="back text-center" @click="$router.back()">{{ $t('Back') }}</span>
    </article>
  </section>
</template>
<script>
export default {
  name: 'Collaborate',
  data: () => ({
    contributeMethods: [
      {
        title: 'Mental',
        how: 'Mentalizing your best toughts for this work. \nAlso, view at your mental screen us and whole humanity growing and prospering - in all ways.',
        hovering: false,
        class: ['']
      },
      {
        title: 'Emotional',
        how: `Feeling your best emotion for this work and for the Creation. Yes, for the Creation. Once there is so much complexity on the existence and in their sub-divisions, it's impossible something to be by randomness (however 'smaller' it is). Even. \nWe're all interconnected. We're all One.`,
        hovering: false,
        class: ['']
      },
    ]
  }),
  methods: {
    swapAndAnimateCardMethodText (event, methodCard) {
      methodCard.class.push('out')

      setTimeout(() => {
        methodCard.hovering = !methodCard.hovering
        methodCard.class = [event.type === 'mouseenter' && 'small-text']
      }, 700)
    },

  }
}
</script>
<style lang="scss" scoped>
  @import '@/assets/scss/_classes.scss';

  section {
    h1, h2 { 
      font-family: 'Pacifico', cursive; 
      text-shadow: 1px 1px 1px gray;
    }

    h1 { font-size: 28px; }

    article {
      width: 100%;
      max-width: 900px;

      & > * {
        margin: 1em 0;
      }
      
      .v-card {
        .v-card__text { 
          color: white !important;
          font-weight: bold;
          text-align: center;
          font-size: 30px;
          transition: opacity 0.7s linear;
          white-space: pre-line;
          opacity: 1;

          &.out { opacity: 0; }

          &.small-text {
            font-size: 15px;
          }
        }   
      }

      span {
        &.back {
          &:hover { 
            cursor: pointer;
            text-decoration: underline; 
          }
        }
      }
    }
  }
</style>